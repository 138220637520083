<template>
  <div>
    <payment-list-filter :user-filter.sync="userFilter" :date-from-filter.sync="dateFromFilter"
      :date-to-filter.sync="dateToFilter" :user-options="userOptions" :client-options="clientOptions"
      :client-filter.sync="clientFilter" :delete-filter.sync="deleteFilter" />
    <b-card no-body class="mb-0">
      <b-overlay :show="tableLoading" spinner-variant="primary" spinner-type="grow" spinner-small rounded="sm">
        <div class="m-2">
          <!-- Table Top -->
          <b-row>
            <!-- Per Page -->
            <b-col cols="12" class="mb-2" style="text-align: center">
              <h3 class="mb-0">收款報表</h3>
            </b-col>
            <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0" v-if="false">
              <label>Show</label>
              <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="perPageOptions"
                :clearable="false" class="per-page-selector d-inline-block mx-50" />
              <label>entries</label>
            </b-col>

            <!-- Search -->
            <b-col cols="12" md="12">
              <div class="d-flex align-items-center justify-content-end">
                <b-button variant="primary" @click="exportReport">
                  <span class="text-nowrap">導出Excel</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>

        <b-table ref="refPaymentListTable" class="payment-relative" :items="fetchPayments" responsive
          :fields="tableReportColumns" primary-key="id" :sort-by.sync="sortBy" show-empty
          empty-text="No matching records found" :sort-desc.sync="isSortDirDesc">
          <!-- Column: Client Code -->
          <template #cell(client)="data">
            <b-link :to="{
              name: 'clients-detail',
              params: { id: data.item.invoice.client.id },
            }">{{ data.item.invoice.client.client_code }}</b-link>
          </template>

          <template #cell(payment_amount)="data">
            {{ getNumberFormat(data.item.payment_amount) }}
          </template>
          <!-- Column: IS AUDIT -->
          <template #cell(is_audit)="data">
            <feather-icon v-if="data.item.is_audit" icon="CheckIcon" size="18" class="mr-50 text-success" />
          </template>

        </b-table>
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BOverlay,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted, onMounted } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import paymentStoreModule from "@/views/payment/paymentStoreModule.js";
import invoiceStoreModule from "@/views/invoice/invoiceStoreModule"
import usePaymentsList from "@/views/payment/payments-list/usePaymentsList.js";
import Ripple from "vue-ripple-directive";
import PaymentListFilter from "@/views/payment/payments-list/PaymentListFilter.vue"
export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    PaymentListFilter,
    BOverlay
  },
  methods: {
    getNumberFormat(amount) {
      var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "HKD",
        minimumFractionDigits: 0,
      });
      return formatter.format(amount);
    },
    exportReport() {
      this.$swal({
        title: "確定導出Excel?",
        showCancelButton: true,
        confirmButtonText: "下載",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.loading = true;
          this.$store
            .dispatch("payment/exportPayments", {
              sortBy: this.sortBy,
              sortDesc: this.isSortDirDesc,
              create_id: this.userFilter,
              client_id: this.clientFilter,
              date_from: this.dateFromFilter,
              date_to: this.dateToFilter,
            })
            .then((response) => {
              // create file link in browser's memory
              const href = URL.createObjectURL(response.data);
              // create "a" HTLM element with href to file & click
              const link = document.createElement("a");
              link.href = href;
              const current_datetime = new Date();
              const formatted_date =
                current_datetime.getFullYear() +
                "-" +
                (current_datetime.getMonth() + 1) +
                "-" +
                current_datetime.getDate() +
                " " +
                current_datetime.getHours() +
                "-" +
                current_datetime.getMinutes() +
                "-" +
                current_datetime.getSeconds();
              link.setAttribute(
                "download",
                "Payments" + formatted_date + ".xlsx"
              );
              document.body.appendChild(link);
              link.click();

              // clean up "a" element & remove ObjectURL
              document.body.removeChild(link);
              URL.revokeObjectURL(href);
            })
            .catch((error) => {
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },

  },
  data() {
    return {
      id: 0,
      paymentData: {},
      invoice_id: null,
      modalId: '-payment-list',
      pdfUrl: '',
    };
  },
  setup() {
    const PAYMENT_STORE_MODULE_NAME = "payment";
    const INVOICE_STORE_MODULE_NAME = "invoice";

    if (!store.hasModule(PAYMENT_STORE_MODULE_NAME))
      store.registerModule(PAYMENT_STORE_MODULE_NAME, paymentStoreModule);
    if (!store.hasModule(INVOICE_STORE_MODULE_NAME))
      store.registerModule(INVOICE_STORE_MODULE_NAME, invoiceStoreModule);


    //Filter 
    const userOptions = ref([])
    const clientOptions = ref([])
    const paymentOptions = ref([])
    const receiptTemplateOptions = ref([])
    const tableLoading = ref(false)

    store.dispatch('app/fetchOptionList', { user: true, client: true, payment_method: true, receipt_template: true })
      .then(response => {
        userOptions.value = response.data.users;
        clientOptions.value = response.data.clients;
        paymentOptions.value = response.data.payment_methods;
        receiptTemplateOptions.value = response.data.receipt_templates;

      })
      .catch(error => {
        console.log('Error in fetching Option List', error)
      })

    const isAddNewAuditSidebarActive = ref(false);
    const isAddNewPaymentSidebarActive = ref(false);

    onMounted(() => {
      currentPage.value = 1;
      perPage.value = null;
    });

    const {
      fetchPayments,
      tableReportColumns,
      perPage,
      currentPage,
      totalPayments,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refPaymentListTable,
      refetchData,
      ability,
      userFilter,
      clientFilter,
      dateFromFilter,
      dateToFilter,
      deleteFilter,
    } = usePaymentsList();

    return {
      //Sidebar
      isAddNewAuditSidebarActive,
      isAddNewPaymentSidebarActive,

      fetchPayments,
      tableReportColumns,
      perPage,
      currentPage,
      totalPayments,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refPaymentListTable,
      refetchData,
      avatarText,
      ability,
      userFilter,
      clientFilter,
      dateFromFilter,
      dateToFilter,
      deleteFilter,
      userOptions,
      clientOptions,
      receiptTemplateOptions,
      paymentOptions,
      tableLoading
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>