import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ability from '@/libs/acl/ability'

export default function usePaymentsList() {

    
    //User toast
    const toast = useToast()

    const refPaymentListTable = ref(null)

    const tableColumns = [
        {key: 'invoice.invoice_number', label:'發票編號', sortable: true},
        {key: 'client', label: '客戶編號'},
        {key: 'invoice.client_company_name_cn', label:'客戶名稱'},
        {key: 'user.name', label:'創建者'},
        //{key: 'item', label:'PAYMENT ITEM'},
        {key: 'payment_amount', label:'付款金額(HK$)', sortable: true},
        {key: 'payment_method.method_name', label: '付款方法'},
        {key: 'payment_date', label: '付款日期', sortable: true},
        {key: 'payment_remark', label: '支付備註'},
        {key: 'document', label: '上傳/查閱收據'},
        //{key: 'invoice'},
        {key: 'receipt', 'label': '收據'},
        {key: 'is_audit', label:'已審核'},
        {key: 'auditor.name', label: '審核者'},
        {key: 'audit_remark', label: '內部備註'},
        {key: 'created_at', label: '創建日期'},
        {key: 'actions', label: '操作'}
    ]
    const tableReportColumns = [
        {key: 'payment_date', label: '付款日期', sortable: true},
        {key: 'receipt_id', 'label': '收據編號'},
        {key: 'invoice.invoice_number', label:'發票編號', sortable: true},
        {key: 'invoice.client.client_code', label: '客戶編號'},
        {key: 'invoice.client_company_name_cn', label:'客戶名稱'},
        {key: 'user.name', label:'創建者'},
        //{key: 'item', label:'PAYMENT ITEM'},
        {key: 'payment_amount', label:'付款金額(HK$)', sortable: true},
        {key: 'payment_method.method_name', label: '付款方法'},
        {key: 'payment_remark', label: '付款備註'},
        {key: 'is_audit', label:'已審核'},
        {key: 'auditor.name', label: '審核者'},
        {key: 'audit_remark', label: '內部備註'}
    ]

    //Filter
    const userFilter = ref(null)
    const clientFilter = ref(null)
    const dateFromFilter = ref(null)
    const dateToFilter = ref(null)
    const deleteFilter = ref(null)
    const paymentStatusFilter = ref(null)

    const perPage = ref(10)
    const totalPayments = ref(0)
    const currentPage = ref(1)
    const perPageOptions = [10, 25, 50, 100]
    const searchQuery = ref('')
    const sortBy = ref('payment_date')
    const isSortDirDesc = ref(true)

    const dataMeta = computed(() => {
        const localItemsCount = refPaymentListTable.value ? refPaymentListTable.value.localItems.length : 0
        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalPayments.value,
        }
    })

    const refetchData = () => {
        refPaymentListTable.value.refresh()
    }

    watch([currentPage, perPage, searchQuery, userFilter, clientFilter, dateFromFilter, dateToFilter,deleteFilter, paymentStatusFilter], () => {
        refetchData()
    })

    const fetchPayments = (ctx, callback) => {
        store.dispatch('payment/fetchPayments',{
            q: searchQuery.value,
            perPage: perPage.value,
            page: currentPage.value,
            sortBy: sortBy.value,
            sortDesc: isSortDirDesc.value,
            create_id: userFilter.value,
            client_id: clientFilter.value,
            date_from: dateFromFilter.value,
            date_to: dateToFilter.value,
            is_deleted: deleteFilter.value,
            payment_status: paymentStatusFilter.value
        })
        .then(response =>{
            const {payments, total} = response.data
            callback(payments)
            totalPayments.value = total
        
        })
        .catch((error) => {
            console.log(error)
            toast({
                component: ToastificationContent,
                props: {
                    title: 'Error fetching payment list',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger'
                }
            })
        })
    }

    const paymentStatusOptions = ref([
        {value: null, text: '全部', listName: '發票列表'},
        {value: 1, text: '待支付', listName: '待支付列表'},
        {value: 2, text: '已支付待審核', listName: '已支付審核數列表'},
        {value: 3, text: '已審核', listName: '已審核列表'},
        {value: 4, text: '已刪除', listName: '已刪除列表'},
    ]);

    return {
        fetchPayments,
        tableColumns,
        perPage,
        currentPage,
        totalPayments,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refPaymentListTable,
        ability,
        refetchData,
        userFilter,
        clientFilter,
        dateFromFilter,
        dateToFilter,
        deleteFilter,
        tableReportColumns,
        paymentStatusFilter,
        paymentStatusOptions
    }

}